import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import router from '@router'
import 'vant/lib/index.css';
import { Dialog } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import i18n from '@/lang'
import './assets/style/index.css' /*引入公共样式*/
import navBar from "./components/navbar"   //引入组件
import { Toast } from 'vant';
import { Loading } from 'vant';
import Bridge from './network/JSbridge.js'
Vue.component("navbar-view",navBar);
Vue.prototype.$bridge = Bridge
Vue.config.productionTip = false

Vue.use(Loading);
Vue.use(Toast);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Toast.setDefaultOptions({ duration: 3000 });
// 全局注册Vant的Dialog
Vue.use(Dialog);
Vue.use(Vant);

//自定义全局的后台请求
import $http from '@network/default'
Vue.prototype.$http = $http

new Vue({
  render: h => h(App),
  router,
  i18n
}).$mount('#app')
