<template>
  <div id="app">
    <!-- 先不缓存 -->
    <!-- <keep-alive> -->
      <router-view />
    <!-- </keep-alive> -->
  </div>
</template>

<script>
export default {
  name: "App",
  data(){
    return{
      navigator:'',
      languageSet:'',
    }
  },
   mounted() {
  this.navigator =navigator.language;
    if(navigator.language =='zh-CN'){
      localStorage.setItem("languageSet", 'zh');
    }else if(navigator.language =='en-CN'){
        localStorage.setItem("languageSet", 'en');         
    }else if(navigator.language =='es-CN'){
        localStorage.setItem("languageSet", 'es');       
    }
    this.languageSet = window.localStorage.getItem('languageSet');
  if (window.localStorage.getItem('languageSet')) {
      if(window.localStorage.getItem('languageSet') =='zh'){
        this.$i18n.locale = "zh";
      }else if(window.localStorage.getItem('languageSet') =='en'){
        this.$i18n.locale = "en";
        }else if(window.localStorage.getItem('languageSet') =='es') {
        this.$i18n.locale = "es";
        }
  }else{
      this.$i18n.locale = "es";
  }
  },
};
</script>

<style>
</style>
