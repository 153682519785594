export default {
  success:'成功',
  error:'错误',
  token:'认证过期，请重新登录',
  // 按页面分组命名
  download: {
    title: "O-Pocket",
    title1: "扫描二维码",
    title2: "下载O-Pocket手机客户端",
    download:'下载',
  },
  navbar: {
    home: "首页",
    message: "消息",
    mine: "我的",
  },
  login: {
    title1: "欢迎",
    title2: "登录使用O-Pocket",
    login: "登录",
    username: "请输入账号",
    password: "请输入密码",
    for_password: "忘记密码？",
    create_password: "新用户注册",
  },
  singin: {
    title1: "请填写",
    title2: "个人注册资料，验证店员身份",
    phone: "手机号",
    phone_warn: "请输入手机号",
    name: "姓名",
    name_warn: "请输入姓名",
    idCard: "身份证",
    idCard_warn: "请输入身份证号",
    bankNo: "银行账号",
    bankNo_warn: "请输入银行帐号",
    bankName: "银行名称",
    bankName_warn: "请选择银行名称",
    next: "验证并继续",
  },
  setPass: {
    title1: "请设置",
    title2: "登录的帐号和密码",
    title3:'新的登录密码',
    account: "登录帐号",
    account_warn: "请输入邮箱或者手机号",
    password: "密码",
    password_warn: "请设置登录密码",
    password_warn2: "密码为8-16位数字，字母或者字符任意2种组成",
    cfm_pass: "确认密码",
    cfm_pass_warn: "重新输入密码",
    cfm_pass_warn2: "请输入正确内容",
    cfm_warn: "俩次输入不一致",
    singup: "注册",
    submit:'提交'
  },
  editBank:{
    title:'修改银行账户',
    bankName:'银行名称',
    bankName_warn:'请选择银行名称',
    bankNo:'银行账户',
    save:'保存'
  },
  stringCode: {
    pleaseEnter:'串码不能为空！',
    pleaseEnterPath:'请上传销售凭证！',
    title: "O-Pocket",
    Scan: "扫描串码",
    Enter: "输入串码",
    ForWithdrawal: "待提现",
    Tips: "串码核销",
    content: "请确认核销串码，若在活动中核销成功即可获取奖励金。",
    fail: "核销失败",
    activatedfail: "核销失败",
    UNAUDITED: "串码待确认",
    WAITACTIVE:'串码待激活',
    REJECTED: "核销驳回",
    Cancel: "取消",
    Done: "确认",
    sale1:'销售一台',
    sale2:'',
    Congratulations: "恭喜你!",
    sorry: "很遗憾",
    saleMessage1:'销量＜',
    saleMessage2:'台，暂无奖励，请继续加油',
    saleCountMessage1:'已销售',
    saleCountMessage2:'台，还差',
    saleCountMessage3:'台，您可获得每台',
    saleCountMessage4:'奖励',
    Rewards: "获得奖励",
    extraMessage1:'(第',
    extraMessage2:'台',
    extraMessage3:',1-',
    extraMessage4:'台追加奖励',
    extraMessage5:')',
    maxsaleMessage1:'已销售',
    maxsaleMessage2:'台，您非常优秀，继续加油，销售越多，奖励越多！',
    Withdraw: "提现",
    ikonw:'我知道了',
    ForWithdrawalRewards: "待提现奖励",
    RewardsCount:'提现笔数',
    items:'笔',
    imei:'IMEI *',
    iMEIsalesVoucher:'IMEI核销凭证 *',
    iMEIsalesPhoto:'点击上传IMEI销售凭证照片',
    errorText:'加载失败',
    
  },
  message: {
    title: "消息",
    announcement: "平台公告",
    learnMore: "查看详情",
    unread: "未读",
    read: "已读",
    detail: "公告详情",
    normalError: "服务器异常"
  },
  withdraw: {
    WithdrawalAmount: "提现金额",
    Withdrawnto: "银行账户",
    sureWithdraw: "确认提现",
    fail: "提现失败",
    Done: "确认",
  },
  withdrawResult: {
    title: "提现进度",
    Request: "发起提现申请",
    Processing: "处理中",
    Success: "到账成功",
    monetaryUnit:'瓜拉尼',
  },
  settle: {
    settleOrder: "结算订单",
    settleTime: "结算时间",
    settleCount: "数量 / 台",
    settleAmount: "结算金额 / PYG",
    settled: '成功',
    settling: "结算中",
    settleFailed: "失败",
    settleDetail: "结算详情",
    settleNum: "结算单号",
    recipient: "收款人",
    loading: "加载中...",
    receiveAccount: '收款账户',
    receiveBank: '收款银行',
    postScript: "附言",
    remark: "备注",
    saleDetails: '销售明细',
    date: '日期',
    retailer: '零售商',
    saleCount: '数量',
    incentive: "奖励",
    finishTime: '完成时间',
  },
  mine: {
    mine: "我的",
    clerkType1: "促销员",
    // clerkType2: "公司导购",
    clerkType3:'零售商',
    clerkType5:'零售商店员',
    totalSale: "总核销/台",
    totalAmount: "总收入/瓜拉尼",
    Imeiorders: "核销记录",
    Bankcard: "银行账户",
    Settings: "应用设置",
    Language: "多语言",
    ResetPassword: "重置密码",
    Loginout: "退出登录",
    Loginout_wran:'确认要退出登录吗？',
    done: "确定",
    English: "英文",
    chinese: "简体中文",
    MyBankCard: "银行账户",
    none:'暂无数据',
    nomore:'没有更多数据',
  },
  record: {
    title: "核销记录",
    tab1: "全部",
    tab2: "待审核",
    tab3: "待提现",
    VerifyFail: "核销失败",
    Tobeverified: "待审核",
    English: "英文",
    chinese: "简体中文",  
     recordDetailTitle:'核销详情',
    verifyOrActivateTime:'核销/激活时间：',
    verifyStatus:'订单状态',
    createTime:'创建时间',
    storeCode:'门店编码',
    storeName:'门店名称',
    points:'奖励金额',
    monetaryUnit:'瓜拉尼',
    withdrawStatus:'提现状态',
    remark:'备注',
    Withdraw:'提现',
    salesVoucher:'销售凭证'
  },
  home:{
    quit:'退出',
    chooseImage:'选择图片'
  },
  language:{
    chinese:'中文 (CN)',
    English:'English (EN)',
    SPANISH:'Español (ES)'
  },
};
