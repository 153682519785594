
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const routesMap = [
    {
        path: '',
        name:'home',
        redirect: '/home',
        component: () => import('@pages/home/home'),
    },
    {
        path: '/home',
        component: () => import('@pages/home/home'),
    },
    {
        path: '/login',
        component: () => import('@pages/login'),
    },
    {
        path: '/download',
        component: () => import('@pages/download'),
    },
    {
        path: '/forgetPassword',
        component: () => import('@pages/forgetPassword'),
    },
    {
        path: '/setPassword',
        component: () => import('@pages/setPassword'),
    },
    {
        path: '/singin',
        component: () => import('@pages/singin'),
    },
    {
        path: '/resetVerify',
        component: () => import('@pages/resetVerify'),
    },
    {
        path: '/bankNameList',
        component: () => import('@pages/bankNameList'),
    },
    {
        path: '/editBank',
        component: () => import('@pages/editBank'),
    },
    {
        path: '/record',
        component: () => import('@pages/record'),
    },
    {
        path: '/mine',
        component: () => import('@pages/mine'),
    },
    {
        path: '/message',
        name:'message',
        component: () => import('@pages/message'),
    },
    {
        path: '/messageDetail',
        name:'messageDetail',
        component: () => import('@pages/messageDetail'),
    },
    {
        path: '/withdraw',
        name:'withdraw',
        component: () => import('@pages/withdraw'),
    },
    {
        path: '/withdrawResult',
        name:'withdrawResult',
        component: () => import('@pages/withdrawResult'),
    },
    {
        path: '/recordDetail',
        name:'recordDetail',
        component: () => import('@pages/recordDetail'),
    },
    {
        path: '/bankCount',
        component: () => import('@pages/bankCount'),
    },
    {
        path: '/setting',
        component: () => import('@pages/setting'),
    },
    {
        path: '/language',
        component: () => import('@pages/language'),
    },
    {
        path: '/settleOrder',
        name: 'settleOrder',
        component: () => import('@pages/settleOrder')
    },
    {
        path: '/settleDetail/:id',
        name: 'settleDetail',
        component: () => import('@pages/settleDetail')
    },
    {
        path: '/404',
        meta: { title: '404', noCache: true },
        component: () => import('@pages/404'),
    }
]

const router = new Router({
    mode: 'hash',
    scrollBehavior: () => ({ y: 0 }),
    routes: routesMap
})

const whiteList = ['/login','/singin','/setPassword','/resetVerify','/forgetPassword','/bankNameList','/download'] // 白名单，不需要token的也能进入的路由
router.beforeEach((to, from, next) => {
    console.log(to, 'dwadwadwa');
    console.log(window.localStorage.getItem('token'));
    // 有token的情况
    if (window.localStorage.getItem('token')) {
        next()
    } // 没有token的情况
    else {
        // 是黑名单里面的直接进入
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            next(`/login?redirect=${to.path}`) // 方便退出之后重新登录进入该页面
        }
    }
})

router.afterEach(() => {
    console.log('after');
})


export default router
// export default new Router({
//     mode: 'hash',
//     scrollBehavior: () => ({ y: 0 }),
//     routes: routesMap
// })
