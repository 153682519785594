export default {
  success:'success',
  error:'error',
  token:'Authentication expired. Please log in again',
  // 按页面命名
  download: {
    title: "O-Pocket",
    search: "search",
    logOut: "Log Out",
    dashboard: "Dashboard",
    github: "Github",
    screenfull: "screenfull",
    theme: "theme",
    lang: "i18n",
    error: "error log",
    title1: "Scan QR code",
    title2: "Download the O-Pocket client",
    download:'download',
  },
  navbar: {
    home: "Home",
    message: "Messages",
    mine: "Me",
  },
  login: {
    title1: "Welcome",
    title2: "Login to use O-Pocket",
    login: "Login",
    username: "Account",
    password: "Password",
    for_password: "Forgot password？",
    create_password: "Create an account",
  },
  singin: {
    title1: "",
    title2: "Identity authentication",
    phone: "Mobile number",
    phone_warn: "Enter your contact number",
    name: "Name",
    name_warn: "Enter your name",
    idCard: "CNIC",
    idCard_warn: "Enter your CNIC",
    bankNo: "Bank card number",
    bankNo_warn: "Enter your bank card number",
    bankName: "Bank name",
    bankName_warn: "Choose your bank name",
    next: "Verify and Continue",
  },
  setPass: {
    title1: "Please set your login",
    title2: "account and password",
    title3:'New Password',
    account: "Account",
    account_warn: "Use your mail or mobile number",
    password: "Password",
    password_warn: "Enter your password",
    password_warn2:
      "Password must be  composed of  8~16 digits, letters or characters，",
    cfm_pass: "Confirm password",
    cfm_pass_warn: "Enter the password again",
    cfm_pass_warn2: "Please enter the correct content",
    cfm_warn: "The passwords entered twice are inconsistent",
    singup: "Sign up",
    submit:'Submit'
  },
  editBank:{
    title:'Edit bank account',
    bankName:'Bank Name',
    bankName_warn:'Choose your bank name',
    bankNo:'Bank Account',
    save:'Save'
  },
  stringCode: {
    pleaseEnter:'String code cannot be empty！',
    pleaseEnterPath:'Please upload the invoice！',
    title: "O-Pocket",
    Scan: "Scan IMEI",
    Enter: "Input IMEI",
    ForWithdrawal: "To be withdrawed",
    Tips: "Verify",
    content:
      "Please check the IMEI number! You can get the rewards  only when the imei is valid and the phone has been active.",
    fail: "Failed",
    activatedfail: "Failed",
    UNAUDITED: "To be approved",
    WAITACTIVE:'To be activated',
    REJECTED: "Refused",
    Cancel: "Cancel",
    Done: "Done",
    Congratulations: "Congratulations!",
    sale1:'',
    sale2:'has been active!',
    sorry: "Sorry",
    saleMessage1:'Sales <',
    saleMessage2:'units, no reward currently, please try and get reward!',
    // 1 unit already sold, you can get Rs.800.00/unit if sell 2 units more!
    saleCountMessage1:'unit already sold, you can get Rs.',
    saleCountMessage2:'/unit if sell ',
    saleCountMessage3:'units more!',
    // (Rs.800.00 for the 3rd unit, Rs.1600.00 for sold units)
    extraMessage1:'(Rs.',
    extraMessage2:' for the ',
    extraMessage3:'rd unit, Rs.',
    extraMessage4:' for sold units',
    maxsaleMessage1:'',
    maxsaleMessage2:'units have been sold, excellent and keep on going, more sales more rewards!',

    Rewards: "Rewards",
    Withdraw: "Withdraw",
    ikonw:'I got it',
    ForWithdrawalRewards: "Reward to be withdrawed",
    RewardsCount:'Number of withdrawals',
    items:'items',
    imei:'IMEI *',
    iMEIsalesVoucher:'Invoice*',
    iMEIsalesPhoto:'Click to upload photo of invoice',
    errorText:'Loading failed',
    
  },
  withdraw: {
    WithdrawalAmount: "Withdrawal Amount",
    Withdrawnto: "Bank account",
    sureWithdraw: "Withdraw",
    fail: "Failed",
    Done: "Done",
  },
  withdrawResult: {
    title: "Withdraw",
    Request: "Request",
    Processing: "Processing",
    Success: "Successed",
    monetaryUnit:'PYG',
  },
  settle: {
    settleOrder: "Settlement order",
    settleTime: "Settlement time",
    settleCount: "Quantity / unit",
    settleAmount: "Amount / PYG",
    settled: "Success",
    settling: "Ongoing",
    settleFailed: "Fail",
    settleDetail: "Settlement details",
    settleNum: "Settlement number",
    recipient: "Recipient",
    incentive: "Incentives",
    loading: 'Loading...',
    receiveAccount: "Receiving account",
    receiveBank: "Receiving bank",
    postScript: "Post script",
    remark: "Remark",
    saleDetails: "Sales details",
    date: "Date",
    retailer: "Retailer",
    saleCount: "Number",
    incentive: "Sales incentives",
    finishTime: 'Completion time',
  },
  message: {
    title: "Messages",
    announcement: "Platform announcement",
    learnMore: "Learn more",
    unread: "Unread",
    read: "Read",
    detail: "Announcement Details",
    normalError: "The server is abnormal",
  },
  mine: {
    mine: "Me",
    clerkType1: "Shop Salesman",
    // clerkType2: "Company promoter",
    clerkType3:'Retailer',
    clerkType5:'Retailer Clerk',
    totalSale: "My sales",
    totalAmount: "My rewards",
    Imeiorders: "Verification Orders",
    Bankcard: "My Bank Account",
    Settings: "Settings",
    Language: "Language",
    ResetPassword: "Reset password",
    Loginout: "Login out",
    Loginout_wran:'Are you sure to log out?',
    done: "done",
    English: "English",
    chinese: "chinese",
    MyBankCard: "My Bank Account",
    none:'No Data',
    nomore:'No More Data'
  },
  record: {
    title: "Verification Orders",
    tab1: "All",
    tab2: "To be approved",
    tab3: "To be withdrawed",
    VerifyFail: "Failed",
    Tobeverified: "To be approved",
    English: "English",
    chinese: "Chinese",
    recordDetailTitle:'Details',
    verifyOrActivateTime:'Sold/Active date',
    verifyStatus:'Order status',
    createTime:'Create time',
    storeCode:'Shop code',
    storeName:'Shop name',
    points:'Reward amount',
    monetaryUnit:'PYG',
    withdrawStatus:'Withdrawal status',
    remark:'Remark',
    Withdraw:'Withdraw',
    salesVoucher:'Invoice'
  },
  home:{
    quit:'quit',
    chooseImage:'chooseImage'
  },
  language:{
    chinese:'中文 (CN)',
    English:'English (EN)',
    SPANISH:'Español (ES)'
  },
};
