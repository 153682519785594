<template>
       <div id="navbar">
      <ul>
        <li @click="chooseTab(1)">
          <img
            class="navbarIcon"
            v-if="selectIndex !== 1"
            src="assets/home.png"
          />
          <img
            class="navbarIcon"
            v-if="selectIndex == 1"
            src="assets/homeActive.png"
          />
          <span class="font" :class="{ selectedColor: selectIndex == 1 }"> {{ $t("navbar.home") }}</span>
        </li>
        <li @click="chooseTab(2)">
          <img
            class="navbarIcon"
            v-if="selectIndex !== 2"
            src="assets/message.png"
          />
          <img
            class="navbarIcon"
            v-if="selectIndex === 2"
            src="assets/messageActive.png"
          />
          <span class="font" :class="{ selectedColor: selectIndex === 2 }">{{ $t("navbar.message") }}</span>
        </li>
        <li @click="chooseTab(3)">
          <img
            class="navbarIcon"
            v-if="selectIndex !== 3"
            src="assets/mine.png"
          />
          <img
            class="navbarIcon"
            v-if="selectIndex === 3"
            src="assets/mineActive.png"
          />
          <span class="font" :class="{ selectedColor: selectIndex === 3 }">{{ $t("navbar.mine") }}</span>
        </li>
      </ul>
    </div>
</template>

<script>
export default {
  props: {
    strmsg: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      selectIndex:null,
    };
  },
  methods:{
       chooseTab(index) {
    var router = window.location.href.split('#/')[1];
      this.selectIndex = index;
      if(router =='home'||router ==""){
        if (index == 2) {
        this.$router.push({ path: "/message" });
      } else if (index == 3) {
        this.$router.push({ path: "/mine" });
      }
      }else if(router =='message'){
        if (index == 1) {
        this.$router.push({ path: "/home" });
      } else if (index == 3) {
        this.$router.push({ path: "/mine" });
      }
      }else if(router =='mine'){
        if (index == 1) {
        this.$router.push({ path: "/home" });
      } else if (index == 2) {
        this.$router.push({ path: "/message" });
      }
      }
    },
  },
  created(){
    console.log( window.location.href.split('#/')[1]);
    console.log( this.selectIndex);
  }
};
</script>

<style  scoped>
#navbar {
  position: absolute;
  width: 100%;
  height: 60px;
  line-height: 60px;
  left: 0px;
  bottom: 0;
  background: #ffffff;
  text-align: center;
}
#navbar ul {
  display: flex;
  align-items: center;
  height: 60px;
}
#navbar ul li {
  flex: 1;
  justify-content: end;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.navbarIcon {
  width: 20px;
}
.selectedColor {
  color: #2dd070!important;
}
.font {
  font-size: 13px;
  line-height: 13px;
  margin-top: 4px;
  color: #999;
}
</style>
