export default {
  success:'éxito',
  error:'error',
  token:'La autenticación expiró, inicie sesión nuevamente',
  // 按页面分组命名
  download: {
    title: "O-Pocket",
    title1: "Escanear código QR",
    title2: "Descargar el cliente móvil O-Pocket",
    download:'descargar',
  },
  navbar: {
    home: "Inicio",
    message: "Mensajes",
    mine: "Yo",
  },
  login: {
    title1: "bienvenido",
    title2: "Inicia sesión para usar O-Pocket",
    login: "Acceso",
    username: "Cuenta",
    password: "Contraseña",
    for_password: "Olvidé mi contraseña?",
    create_password: "Crear cuenta",
  },
  singin: {
    title1: "",
    title2: "Autenticación de identidad",
    phone: "Número de contacto",
    phone_warn: "Por favor, introduzca su número de teléfono",
    name: "Nombre y Apellido",
    name_warn: "Por favor ingrese tu nombre completo según cédula de identificacion",
    idCard: "CI",
    idCard_warn: "Por favor ingrese el número de identificación",
    bankNo: "Número de tarjeta bancaria",
    bankNo_warn: "Por favor ingrese el número de cuenta bancaria",
    bankName: "Nombre del banco",
    bankName_warn: "Seleccionar el nombre de tu banco",
    next: "Verificar y continuar",
  },
  setPass: {
    title1: "Por favor, ",
    title2: "establece una cuenta y contraseña",
    title3:'Nueva contraseña',
    account: "Cuenta",
    account_warn: "Usa tu correo electrónico o número de teléfono",
    password: "Contraseña",
    password_warn: "Por favor establezca una contraseña de inicio de sesión",
    password_warn2: "La contraseña debe tener entre 8 y 16 dígitos, letras o caracteres.",
    cfm_pass: "Confirmar Contraseña",
    cfm_pass_warn: "Por favor vuelva a ingresar la contraseña",
    cfm_pass_warn2: "Por favor ingresa el contenido correcto",
    cfm_warn: "La contraseña no coincide",
    singup: "Crear cuenta",
    submit:'Enviar'
  },
  editBank:{
    title:'Modificar cuenta bancaria',
    bankName:'Nombre del banco',
    bankName_warn:'Seleccionar el nombre de tu banco',
    bankNo:'Cuenta bancaria',
    save:'Guardar'
  },
  stringCode: {
    pleaseEnter:'¡El IMEI no puede estar vacío!',
    pleaseEnterPath:'Por favor, suba la foto de la factura!',
    title: "O-Pocket",
    Scan: "Escanear IMEI",
    Enter: "Ingresar IMEI",
    ForWithdrawal: "Para ser retirado",
    Tips: "Verificar",
    content: "¡Por favor, verifica el número IMEI! Solo puedes obtener las recompensas cuando el IMEI es válido y el teléfono ha estado activo.",
    fail: "Fallido",
    activatedfail: "Fallido",
    UNAUDITED: "Pendiente de aprobación",
    WAITACTIVE:'Pendiente de activación',
    REJECTED: "Rechazado",
    Cancel: "Cancelar",
    Done: "Hecho",
    sale1:'Vender una unidad',
    sale2:'',
    Congratulations: "¡Felicidades!",
    sorry: "Lo siento",
    saleMessage1:'Ventas ＜',
    saleMessage2:' unidades, sin recompensa actualmente, ¡intenta obtenerla!',
    saleCountMessage1:'Se han vendido ',
    saleCountMessage2:'unidades y faltan ',
    saleCountMessage3:'unidades. puede recibir un premio de ',
    saleCountMessage4:'Gs por unidad.',
    Rewards: "Recompensas",
    extraMessage1:'(',
    extraMessage2:' unidades',
    extraMessage3:'1-',
    extraMessage4:'  recompensas adicionales',
    extraMessage5:')',
    maxsaleMessage1:'Se han vendido',
    maxsaleMessage2:'unidades, ¡excelente y sigue así, más ventas significan más recompensas!',
    Withdraw: "Retirar",
    ikonw:'Entendido',
    ForWithdrawalRewards: "Recompensa a retirar",
    RewardsCount:'Número de retiros',
    items:'item',
    imei:'IMEI *',
    iMEIsalesVoucher:'Factura*',
    iMEIsalesPhoto:'Haga clic para subir la foto de la factura',
    errorText:'La carga falló',
  },
  message: {
    title: "Mensajes",
    announcement: "Anuncio",
    learnMore: "Aprende más",
    unread: "No leído",
    read: "Leído",
    detail: "Detalles del anuncio",
    normalError: "El servidor es anormal",
  },
  withdraw: {
    WithdrawalAmount: "Cantidad de retiro",
    Withdrawnto: "Cuenta bancaria",
    sureWithdraw: "Retirar",
    fail: "Fallido",
    Done: "Confirmación",
  },
  withdrawResult: {
    title: "Retirar",
    Request: "Solicitar",
    Processing: "Procesando",
    Success: "Exitoso",
    monetaryUnit:'PYG',
  },
  settle: {
    settleOrder: "Orden de liquidación",
    settleTime: "Tiempo de liquidación",
    settleCount: "Cantidad / unidad",
    settleAmount: "Importe / PYG",
    settled: 'Lograr',
    settling: "Actual",
    settleFailed: "Fallar",
    settleDetail: "Detalles del acuerdo",
    settleNum: "Número de orden de liquidación",
    recipient: "Payee",
    incentive: "Incentivo",
    loading: "Carga...",
    receiveAccount: "Recibiendo cuenta",
    receiveBank: "Banco Receptor",
    postScript: "Post guión",
    remark: "Comentario",
    saleDetails: "Detalles de ventas",
    date: "Cita",
    retailer: "Nombre de tienda",
    saleCount: "Cantidad de ventas",
    incentive: "Incentivo de ventas",
    finishTime: 'Tiempo de finalización',
  },
  mine: {
    mine: "Yo",
    clerkType1: "Vendedor de tienda",
    // clerkType2: "Promotor de la empresa",
    clerkType3:'tienda',
    clerkType5:'clerk-vendedor',
    totalSale: "Mis ventas",
    totalAmount: "Mis recompensas",
    Imeiorders: "Lista de ventas",
    Bankcard: "Mi cuenta bancaria",
    Settings: "Configuraciones",
    Language: "Lenguaje",
    ResetPassword: "Restablecer la contraseña",
    Loginout: "Cerrar sesión",
    Loginout_wran:'¿Estás seguro de cerrar sesión?',
    done: "Determinar",
    English: "Inglés",
    chinese: "Chino",
    MyBankCard: "Cuenta bancaria",
    none:'Sin datos',
    nomore:'no más datos',
  },
  record: {
    title: "Lista de ventas",
    tab1: "Todo",
    tab2: "Pendiente de aprobación",
    tab3: "Para ser retirado",
    VerifyFail: "Fallido",
    Tobeverified: "Pendiente de aprobación",
    English: "Inglés",
    chinese: "Chino",  
    recordDetailTitle:'Detalles',
    verifyOrActivateTime:'Tiempo de cancelación/activación：',
    verifyStatus:'Estado del pedido',
    createTime:'Crear tiempo',
    storeCode:'Código de tienda',
    storeName:'Nombre de tienda',
    points:'Monto de recompensa',
    monetaryUnit:'PYG',
    withdrawStatus:'Estado de retiro',
    remark:'Observación',
    Withdraw:'Retirar',
    salesVoucher:'Factura'
  },
  home:{
    quit:'Cerrar sesión',
    chooseImage:'Seleccionar imagen'
  },
  language:{
    chinese:'中文 (CN)',
    English:'English (EN)',
    SPANISH:'Español (ES)'
  },
};
