import Vue from 'vue'
import axios from "axios";
import router from "@router"
import i18n from '@/lang'
import {
    Dialog
} from 'vant';
// 全局注册Vant的Dialog
Vue.use(Dialog);

let APIURL = process.env.VUE_APP_BASE_API;

const service = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? APIURL  : '/', // api 的 base_url,
    timeout: 50000
    // 调大 前端请求超时时间 统一让后端接口报 前端不报错
})
// 请求拦截器
service.interceptors.request.use(
    // 请求前是否需要配置什么
    config => {
        // TODO 比如：让每个请求携带自定义token 请根据实际情况自行修改
        if (window.localStorage.getItem('token')) {
            config.headers['Authorization'] = window.localStorage.getItem('token')
        }
        if (window.localStorage.getItem('languageSet')) {
            if(window.localStorage.getItem('languageSet') =='zh'){
                config.headers['Language'] = 'CN';
            }else if(window.localStorage.getItem('languageSet') =='en'){
                config.headers['Language'] = 'EN';           
            }else if(window.localStorage.getItem('languageSet') =='es'){
                config.headers['Language'] = 'ES';           
            }
        }else{
            config.headers['Language'] = 'ES';
        }
        // config.headers['Authorization'] = window.localStorage.getItem('token')
        config.headers['Content-Type'] = 'application/json'
        return config
    },
    // 报错提示
    error => {
        Dialog.alert({
            title: '错误',
            message: error,
        }).then(() => {
            // on close
        });
    }
)


// 响应拦截器
service.interceptors.response.use(
    response => {
        // TODO 根据响应码做进一步判断，比如页面跳转、报错、退出重登等等
        console.log(14421421421,response);
        const code = response.data.code ? response.data.code : response.status
        // 因为登录的接口成功没有返回code码 单独做处理
        switch (code) {
            case 400:
                return response.data
                break;
            case '10011':
                Dialog.alert({
                    title: '',
                    message: i18n.t('token'),
                    confirmButtonText:i18n.t('stringCode.Done')
                }).then(() => {
                    window.localStorage.clear()
                    router.push('/login')
                });
                return response.data
                // return Promise.reject('error')
                break;
            case 403:
                // Dialog.alert({
                //     title: '错误',
                //     message: '请求被拒绝',
                // }).then(() => {});
                return response.data
                break;
            case 404:
                router.push({
                    path: '/404'
                })
                break;
            case 500:
                // Dialog.alert({
                //     title: '错误',
                //     message: '服务器出错',
                // }).then(() => {});
                return response.data
                break;

            default:
                return response.data
                break;
        }
        // if (code == 200) {
        //     return response.data
        // }
    },
    error => {
        console.log(error);
            if(error.code){
                return Promise.reject(error)
            }else{
                return Promise.reject({message:'系统异常'})
            }
    
    }
)

export default service